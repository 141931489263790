import React, {useState} from 'react';
import {graphql} from 'gatsby';
import {PageProps} from '../types';
import {PageWrapper, Paragraph, Spacer} from '../styles/page';
import {Layout} from '../components/Layout';
import SEO from '../components/SEO';
import {Button, ButtonVariant} from '../components/Button';
import {
  DisplayMessage,
  DisplayMessageTypes,
} from '../components/DisplayMessage';
import PageWidth from '../components/PageWidth';
import {getPageData, getApiUrlFromData} from '../utils';
import {isValidEmail} from '../utils/validators';
import {Form, Input, InputWrapper, Heading} from '../components/InputHelpers';
import {ResetPasswordPageQuery} from './__generated__/ResetPasswordPageQuery';
import {UserApi} from '@focusrite-novation/ampify-api';
import {createApi, parseApiError} from '../api';

type ResetPasswordPageProps = PageProps<ResetPasswordPageQuery>;

const ResetPassword = (props: ResetPasswordPageProps) => {
  const [email, setEmail] = useState('');
  const [isRequesting, setIsRequesting] = useState(false);
  const [errorMessage, setErrorMessage] = useState('');
  const [success, setSuccess] = useState(false);
  const data = getPageData(props.data);

  const reset = () => {
    setIsRequesting(false);
    setErrorMessage('');
    setSuccess(false);
  };

  const onSubmit = async (e: React.FormEvent) => {
    e.preventDefault();

    reset();

    if (email.length === 0) {
      setErrorMessage('Please enter your email address');
      return;
    }

    if (!isValidEmail(email)) {
      setErrorMessage('Please enter a valid email address');
      return;
    }

    const api = createApi(UserApi, getApiUrlFromData(props.data));

    try {
      setIsRequesting(true);
      await api.postResetPassword({email});
      setSuccess(true);
    } catch (error) {
      try {
        const apiError = parseApiError(error);
        setErrorMessage(apiError?.message);
      } catch (error) {
        setErrorMessage(
          'Sorry, there was a problem sending you a reset password email.'
        );
      }
    }

    setIsRequesting(false);
  };

  return (
    <Layout>
      <SEO
        title={data.pageTitle}
        keywords={data.metaKeywords.split(',')}
        description={data.metaDescription}
      />
      <PageWrapper grey>
        <PageWidth>
          <Heading>{data.pageTitle}</Heading>
          <InputWrapper>
            <Paragraph>{data.userInstructions}</Paragraph>
            <Spacer />

            {errorMessage.length === 0 && success ? (
              <>
                <DisplayMessage
                  type={DisplayMessageTypes.SUCCESS}
                  data-test-id="reset-password-success"
                >
                  <div>We've sent you an email to reset your password</div>
                </DisplayMessage>
                <Button
                  variant={ButtonVariant.BLACK_OUTLINE_WHITE_FILL}
                  data-test-id="reset-form-button"
                  onClick={reset}
                >
                  {data.reRequestButtonText}
                </Button>
              </>
            ) : (
              <>
                {errorMessage ? (
                  <DisplayMessage
                    type={DisplayMessageTypes.ERROR}
                    data-test-id="reset-password-error"
                  >
                    <div>{errorMessage}</div>
                  </DisplayMessage>
                ) : null}

                <Form onSubmit={onSubmit} data-test-id="reset-password-form">
                  <Input
                    id="email"
                    placeholder="Your email address"
                    value={email}
                    onChange={(e: React.ChangeEvent<HTMLInputElement>) =>
                      setEmail(e.target.value)
                    }
                    data-test-id="reset-password-input"
                  />
                  <Button
                    showSpinner={isRequesting}
                    data-test-id="submit-email-reset"
                  >
                    {data.resetButtonText}
                  </Button>
                </Form>
              </>
            )}
          </InputWrapper>
        </PageWidth>
      </PageWrapper>
    </Layout>
  );
};

export const pageQuery = graphql`
  query ResetPasswordPageQuery {
    en: allContentfulAmpifyWebResetPassword(
      filter: {node_locale: {eq: "en-GB"}}
    ) {
      edges {
        node {
          pageTitle
          metaKeywords
          metaDescription
          userInstructions
          resetButtonText
          reRequestButtonText
        }
      }
    }
    site {
      siteMetadata {
        apiUrl
      }
    }
  }
`;

export default ResetPassword;
